import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';
import InitiativeMemberBoxImageWithAvatar from 'components/InitiativeTabs/InitiativeMemberBoxImageWithAvatar';
import { IconShare } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ActionComponent from 'components/@common/ActionComponent';
import messages from './messages';
import { formatDateTime } from 'utils/dateResolvers';
import { colors } from 'utils/constants/colors';
import { reactionType } from 'utils/constants/initiatives';
import { useAddNewsReactionMutation } from 'store/rtk-query/newsApi';
import { imagePathResolver } from 'utils/imageResolvers';

export const NewsItemContainer = styled(Grid)(({ theme }) => ({
  transition: 'background-color 0.3s ease-in-out',
  borderBottom: `1px solid ${theme.palette.primary.mainLight}`,
  padding: '12px',
  '& svg': {
    color: theme.palette.primary.mainLight
  },
  '&:hover': {
    background: theme.palette.primary.main,
    // Adjust styles of children components when NewsContainer is hovered
    '& .MuiTypography-root': {
      color: theme.palette.background.default
    },
    '& .MuiAvatar-root': {
      '& .MuiSvgIcon-root': {
        pointerEvents: 'none',
        fill: 'currentColor'
      }
    },
    '& svg': {
      transition: 'background-color 0.8s ease-in-out',
      background: theme.palette.primary.main,
      color: theme.palette.grey[400]
    }
  }
}));

export const ItemText = styled(Typography)(({ theme }) => ({
  fontFamily: `'Poppins', sans-serif`,
  fontStyle: 'normal',
  textTransform: 'capitalize',
  lineHeight: '110%',
  fontWeight: 400,
  fontSize: '20px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem'
  }
}));
const NewsListing = ({ newsItem, index, initiativeId, showPrivacy, userId }) => {
  const navigate = useNavigate();
  const [addNewsReaction] = useAddNewsReactionMutation();

  const intl = useIntl();

  const { reactions = [], first_name, last_name, created_at, comment_count, profile_image } = newsItem;

  const ownerName = `${first_name} ${last_name}`;
  const likedCount = reactions?.reduce((count, reaction) => {
    return reaction.type === reactionType.LIKE ? count + 1 : count;
  }, 0);

  const disLikedCount = reactions?.reduce((count, reaction) => {
    return reaction.type === reactionType.DISLIKE ? count + 1 : count;
  }, 0);

  const currentReaction = reactions?.find((x) => x.user_id === userId);

  const handleActionButton = async (reaction_Type) => {
    await addNewsReaction({
      body: {
        type: reaction_Type,
        user: {
          uuid: userId
        }
      },
      initiativeId,
      newsId: newsItem.id,
      reactionId: currentReaction?.id
    });
  };

  const handleDetailsPageNavigation = () => {
    navigate(`/initiatives/${initiativeId}/contributions/${newsItem.id}`);
  };

  return (
    <NewsItemContainer mt={index === 0 ? 2 : 0} item container xs={12} sx={{ cursor: 'pointer' }}>
      <Grid item xs={7} onClick={handleDetailsPageNavigation}>
        <Stack gap={1}>
          <ItemText>{newsItem.title}</ItemText>
          <ItemText
            sx={(theme) => ({
              fontSize: '1rem',
              color: colors.dark[400],
              display: '-webkit-box',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
              [theme.breakpoints.down('lg')]: {
                fontSize: '12px'
              },
              fontFamily: `'Poppins', sans-serif !important`,
              '& *': {
                fontFamily: `'Poppins', sans-serif !important`
              }
            })}
            dangerouslySetInnerHTML={{ __html: newsItem?.content }}
          />
        </Stack>
      </Grid>
      <Grid
        item
        xs={2.5}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'left'}
        alignItems={'flex-start'}
        gap={0.5}
        onClick={handleDetailsPageNavigation}
      >
        <Stack direction="row" justifyContent={'center'} gap={2} alignItems={'flex-start'}>
          <InitiativeMemberBoxImageWithAvatar
            avatarSrc={imagePathResolver(profile_image)}
            label1={ownerName}
            label2={formatDateTime(created_at)}
          />
        </Stack>
        {showPrivacy && (
          <>
            <Stack direction={'row'}>
              <Typography sx={{ fontWeight: 500, mr: 0.5 }} fontSize={'0.9rem'} color={colors.dark[550]} variant="h6">
                Privacy:
              </Typography>
              <Typography variant="h6" fontSize={'0.9rem'} color={colors.dark[550]}>
                Restricted
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <Typography variant="h6" sx={{ fontWeight: 500, mr: 0.5 }} fontSize={'0.9rem'} color={colors.dark[550]}>
                Initiative type:
              </Typography>
              <Typography variant="h6" fontSize={'0.9rem'} color={colors.dark[550]}>
                Problem Solving
              </Typography>
            </Stack>
          </>
        )}
      </Grid>
      <Grid item md={2.5} display="flex" justifyContent="flex-end" flexDirection="column" gap={2}>
        <ActionComponent
          sxProps={{ justifyContent: 'flex-end' }}
          likeCount={likedCount}
          dislikeCount={disLikedCount}
          commentCount={comment_count}
          currentReaction={currentReaction}
          handleActionButton={handleActionButton}
          handleCommentActionButton={handleDetailsPageNavigation}
        />
        {showPrivacy && (
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Typography variant="h6" fontWeight={500} color={colors.dark[550]}>
              {intl.formatMessage(messages.Share)}
            </Typography>
            <IconShare />
          </Stack>
        )}
      </Grid>
    </NewsItemContainer>
  );
};

NewsListing.propTypes = {
  newsItem: PropTypes.object,
  index: PropTypes.number,
  userId: PropTypes.string,
  initiativeId: PropTypes.string,
  newsId: PropTypes.string,
  showPrivacy: PropTypes.bool
};

export default NewsListing;
