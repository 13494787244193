/* eslint-disable react/prop-types */
import paths from '@constants/paths';
import { Box, Button, Card, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { dispatch } from 'store';
import { setTourState } from 'store/reducers/tourGuideReducer';
import { useAddTourStatesMutation } from 'store/rtk-query/tourApi';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: '1px 10px'
}));

const Tooltip = ({ continuous, index, step, backProps, closeProps, skipProps, primaryProps, tooltipProps, isLastStep }) => {
  return (
    <Card {...tooltipProps} sx={{ padding: '20px 10px', background: (theme) => theme.palette.secondary.cardBackground }}>
      {step.title && <Typography>{step.title}</Typography>}
      <Box sx={{ padding: '5px' }}>{step.content}</Box>
      <Stack direction={'row'} justifyContent={'space-between'} gap={5}>
        <StyledButton {...skipProps} variant="contained">
          {`don't show again`}
        </StyledButton>
        <Stack direction={'row'} gap={2} justifyContent={'end'}>
          <StyledButton {...(index === 0 ? { ...closeProps } : { ...backProps })} variant="contained">
            {index === 0 ? 'Close' : 'Previous'}
          </StyledButton>
          {continuous && (
            <StyledButton {...primaryProps} variant="contained" sx={{ bgcolor: 'dark.labelTextColor' }}>
              {isLastStep ? 'Done' : 'Next'}
            </StyledButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
const TourComponent = () => {
  const { palette } = useTheme();
  const { tourState } = useSelector((state) => state.tourGuideReducer);
  const { pathname } = useLocation();
  const [addTourState] = useAddTourStatesMutation();
  const bodyHeight = document.body.scrollHeight;

  const handleJoyrideCallback = async (data) => {
    const { action, index, type, status } = data;
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch(setTourState({ type: 'NEXT_OR_PREV', payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) } }));
    } else if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && tourState.run) || status === STATUS.FINISHED) {
      dispatch(setTourState({ type: 'STOP' }));
      await addTourState(pathname.includes('/chat') ? paths.Chats.url : pathname);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const portalElement = document.querySelector('.react-joyride__overlay');
        if (portalElement) {
          portalElement.style.height = `${bodyHeight}px`;
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect(); // Clean up the observer when the component unmounts
    };
  }, [bodyHeight]);

  return (
    <Joyride
      {...tourState}
      callback={handleJoyrideCallback}
      disableScrolling
      styles={{
        options: {
          zIndex: 9999,
          arrowColor: palette.secondary.cardBackground
        }
      }}
      tooltipComponent={Tooltip}
    />
  );
};

export default TourComponent;
