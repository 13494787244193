import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import InitiativeActivitiesSkeleton from 'components/@skeletons/InitiativeActivities';
import NoMoreDataComponent from 'components/Accessiblity/InsightBox';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InviteOrAddNetwork from 'components/InitiativeInfoSection/InviteOrAddNetwork';
import toast from 'react-hot-toast';
import globalConstant from '@constants/globalConstant';
import messages from '../messages';
import { StyledScrolledGrid } from '../DetailsTab';
import { useInitiativeData, useNetworkData } from './NetworkTabHook';
import NetworkList from './NetworkList';
import paths from 'utils/constants/paths';
import { isAdministrator } from 'utils/utils';
import { useLazyGetNetworkListQuery } from 'store/rtk-query/networkApi';

const NetworkTab = () => {
  const { initiativeId } = useParams();
  const [multipleInviteeList, setMultipleInviteeList] = useState([]);
  const [triggerNetworkQuery, { isLoading: showRecommendationLoading }] = useLazyGetNetworkListQuery();

  const intl = useIntl();

  const {
    userId,
    initiativeDetails,
    initiativeMembers,
    isMembersLoading,
    initiativeAdministrators,
    isAdminsLoading,
    initiativeOwner,
    isOwnerLoading,
    orgAuthority,
    isOrgLoading,
    initiativeAdministratorInvitees
  } = useInitiativeData(initiativeId);

  const {
    ownerData = [],
    allMembersData = [],
    allAdminData = []
  } = useNetworkData(
    initiativeDetails,
    initiativeMembers,
    initiativeAdministrators,
    isAdminsLoading,
    initiativeOwner,
    isOwnerLoading,
    orgAuthority,
    isMembersLoading,
    isOrgLoading,
    initiativeAdministratorInvitees
  );
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const removeOwnerFromAdministrators =
    initiativeDetails?.administrators.filter(({ email }) => email !== initiativeDetails?.createdBy) || [];

  const fetchNetworkList = useCallback(
    async (filteredKeywords) => {
      try {
        const enterprisesResponse = await triggerNetworkQuery({
          networkType: 'enterprises',
          limit: globalConstant.DEFAULT_LIMIT_RECOMMENDED_USERS,
          filterKeywords: filteredKeywords.map((item) => item.title).join(','),
          offset: 0,
          filterUserIds: [
            ...removeOwnerFromAdministrators,
            ...(initiativeDetails?.invitees || []),
            ...(initiativeDetails?.organizationAuthority || []),
            ...(initiativeMembers || [])
          ]
            .map((item) => item.uuid)
            .join(',')
        }).unwrap();

        const withoutZeroMatchPercentageEnterPriseList = enterprisesResponse?.resultList?.filter((item) => item?.matchingScore > 0);
        setEnterpriseList(withoutZeroMatchPercentageEnterPriseList);

        const personsResponse = await triggerNetworkQuery({
          networkType: 'persons',
          limit: globalConstant.DEFAULT_LIMIT_RECOMMENDED_ORGS,
          filterKeywords: filteredKeywords.map((item) => item.title).join(','),
          offset: 0,
          filterUserIds: [
            ...removeOwnerFromAdministrators,
            ...(initiativeDetails?.invitees || []),
            ...(initiativeDetails?.organizationAuthority || []),
            ...(initiativeMembers || [])
          ]
            .map((item) => item.uuid)
            .join(',')
        }).unwrap();
        const withoutZeroMatchPercentageUsersList = personsResponse?.resultList?.filter((item) => item?.matchingScore > 0);
        setUsersList(withoutZeroMatchPercentageUsersList);
      } catch (error) {
        toast.error('Error fetching network list');
        console.error(error);
      }
    },
    [
      triggerNetworkQuery,
      removeOwnerFromAdministrators,
      initiativeDetails?.invitees,
      initiativeDetails?.organizationAuthority,
      initiativeMembers
    ]
  );
  useEffect(() => {
    fetchNetworkList(initiativeDetails?.keywords);
  }, [initiativeDetails?.keywords, initiativeMembers]);

  const visibleForAdministrators = useMemo(() => {
    if (initiativeDetails && userId) {
      return isAdministrator(initiativeDetails, userId);
    }
  }, [initiativeDetails, userId]);

  const combinedAllNetworkData = useMemo(() => {
    const data = {
      Owner: ownerData?.[0] || [],
      Administrators: allAdminData,
      Enterprises: allMembersData.filter((member) => member.label === 'Enterprise'),
      Members: allMembersData.filter((member) => member.label === 'Member')
    };
    return data;
  }, [ownerData, allAdminData, allMembersData]);

  if (isMembersLoading || isOrgLoading) return Array.from(new Array(5)).map((_, index) => <InitiativeActivitiesSkeleton key={index} />);

  if (!Object.values(combinedAllNetworkData)?.some((y) => y?.length !== 0))
    return (
      <NoMoreDataComponent
        redirectLink={`${paths.Initiatives.url}/${initiativeId}`}
        description={intl.formatMessage(messages.NoMembersYetInInitiatives)}
        title={intl.formatMessage(messages.NoNetworkYet)}
        redirectButtonText={intl.formatMessage(messages.BackToInitiaitive)}
      />
    );

  return (
    <Grid container>
      <StyledScrolledGrid item xs={12} md={visibleForAdministrators ? 9 : 12}>
        <NetworkList
          combinedAllNetworkData={combinedAllNetworkData}
          visibleForAdministrators={visibleForAdministrators}
          userId={userId}
          ownerData={ownerData}
        />
      </StyledScrolledGrid>
      {visibleForAdministrators && (
        <StyledScrolledGrid item xs={12} md={visibleForAdministrators && 3}>
          <InviteOrAddNetwork
            multipleInviteeList={multipleInviteeList}
            setMultipleInviteeList={setMultipleInviteeList}
            initiativeId={initiativeId}
            userId={userId}
            administrators={[...allAdminData, ...ownerData]}
            members={allMembersData}
            showRecommendation
            recommendationUsersList={usersList}
            recommendationOrganizationList={enterpriseList}
            showRecommendationLoading={showRecommendationLoading}
            removeTop
            filterUserIds={[...removeOwnerFromAdministrators, ...[initiativeMembers?.invitees || []], ...(initiativeMembers || [])]
              .map((item) => item.uuid)
              .join(',')}
            filteredKeywords={initiativeDetails?.keywords}
            removeOwnerFromAdministrators={removeOwnerFromAdministrators}
            initiativeMembers={initiativeMembers}
            setUsersList={setUsersList}
            setEnterpriseList={setEnterpriseList}
          />
        </StyledScrolledGrid>
      )}
    </Grid>
  );
};

export default NetworkTab;
