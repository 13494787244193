/* eslint-disable no-undef */
import { createApi } from '@reduxjs/toolkit/query/react';
import { RtkBaseQuery } from 'utils/request';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';

export const usersListApi = createApi({
  reducerPath: 'searchUserAPI',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.searchUser, queryTags.allUser, queryTags.allUsersInformation],
  endpoints: (builder) => ({
    getSearchedUser: builder.query({
      query: (params) => queryEndPoints.getSearchedUser(params),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.searchUser]
    }),
    usersPaymentInformationList: builder.query({
      query: ({ offset, limit, search, sortBy, sortIn }) => queryEndPoints.usersPaymentInformationV1(offset, limit, search, sortBy, sortIn),
      providesTags: [queryTags.allUsersInformation],
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName
    }),
    sendConfirmEmail: builder.mutation({
      query: ({ body, sendMail }) => ({
        url: queryEndPoints.sendSetPasswordMail(sendMail),
        method: API_REQUEST_METHOD.POST,
        body
      })
      // TODO : uncomment this when invalidation necessary
      // invalidatesTags: [queryTags.allUsersInformation]
    })
  })
});

export const { useLazyGetSearchedUserQuery, useUsersPaymentInformationListQuery, useSendConfirmEmailMutation } = usersListApi;
