import { useMemo } from 'react';
import {
  useGetInitiativeAdministratorsQuery,
  useGetInitiativeMembersQuery,
  useGetInitiativeOwnerQuery,
  useGetInitiativesByIdQuery
} from 'store/rtk-query/initiativesApi';
import { useGetInitiativeAdministratorInviteesQuery, useGetOrganizationAuthorityQuery } from 'store/rtk-query/inviteeApi';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';

export const useInitiativeData = (initiativeId) => {
  const { data: { uuid: userId } = {} } = useGetUserInfoQuery();
  const { data: initiativeDetails } = useGetInitiativesByIdQuery({ userId, initiativeId }, { skip: !userId || !initiativeId });
  const removeOwnerFromAdministrators = (initiativeDetails?.administrators || []).filter(
    ({ email }) => email !== initiativeDetails?.createdBy
  );
  const { data: initiativeMembers, isLoading: isMembersLoading } = useGetInitiativeMembersQuery(
    {
      initiativeId,
      filterUserIds: removeOwnerFromAdministrators?.map((item) => item.uuid).join(',')
    },
    {
      skip: !initiativeId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true
    }
  );
  const { data: initiativeAdministrators, isLoading: isAdminsLoading } = useGetInitiativeAdministratorsQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const { data: initiativeOwner, isLoading: isOwnerLoading } = useGetInitiativeOwnerQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const { data: orgAuthority, isLoading: isOrgLoading } = useGetOrganizationAuthorityQuery(
    { initiativeId },
    { skip: !initiativeId, refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const { data: initiativeAdministratorInvitees = [] } = useGetInitiativeAdministratorInviteesQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  return {
    userId,
    initiativeDetails,
    initiativeMembers,
    isMembersLoading,
    initiativeAdministrators,
    isAdminsLoading,
    initiativeOwner,
    isOwnerLoading,
    orgAuthority,
    isOrgLoading,
    initiativeAdministratorInvitees
  };
};

export const useNetworkData = (
  initiativeDetails,
  initiativeMembers,
  initiativeAdministrators,
  isAdminsLoading,
  initiativeOwner,
  isOwnerLoading,
  orgAuthority,
  isMembersLoading,
  isOrgLoading,
  initiativeAdministratorInvitees
) => {
  const ownerData = useMemo(() => {
    const owner = initiativeOwner;
    return owner ? [{ ...owner, label: 'Owner' }] : [];
  }, [initiativeOwner]);

  const allMembersData = useMemo(() => {
    if (!isMembersLoading && !isOrgLoading) {
      return (
        [...(orgAuthority || []), ...(initiativeMembers || [])].map((x) => ({ ...x, label: x.firstName ? 'Member' : 'Enterprise' })) || []
      );
    }
    return [];
  }, [isMembersLoading, isOrgLoading, initiativeMembers, orgAuthority]);

  const allAdminData = useMemo(() => {
    if (!isAdminsLoading && !isOrgLoading) {
      const adminData =
        initiativeAdministrators?.map((x) => ({
          ...x,
          label: 'Administrator',
          isAdminInvitee: false // Explicitly mark administrators
        })) || [];

      const inviteeData =
        initiativeAdministratorInvitees?.map((x) => ({
          ...x,
          label: 'Administrator',
          isAdminInvitee: true // Mark invitees
        })) || [];

      const combinedData = [...adminData, ...inviteeData];

      // Exclude owner from the combined data
      return combinedData.filter((y) => y?.uuid !== ownerData[0]?.uuid);
    }
    return [];
  }, [initiativeAdministrators, initiativeAdministratorInvitees, isAdminsLoading, isOrgLoading, ownerData]);

  return {
    ownerData,
    allMembersData,
    allAdminData
  };
};
