/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  useMediaQuery,
  Stack,
  Paper,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Popper,
  Box,
  useTheme,
  Avatar
} from '@mui/material';
import { IconUser } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { StyledRenderHtmlBox } from 'components/InitiativeInfoSection/InitiativesInfoBox';
import NotificationSkeleton from 'components/@skeletons/NotificationSkeleton';
import { getCapitalizeFirst } from '@utils/getCapitalize';
import { initiativeTabs } from '@constants/initiatives';
import InnofuseLogo from '../../assets/images/Favicon_innofuse.svg';
import { MainCard, Transitions } from '.';
import { notificationStatus, notificationType } from 'utils/constants/globalConstant';
import { getMinuteDurationFromNow } from 'utils/dateResolvers';
import { useLazyMarkedAsReadNotificationQuery, useLazySingleNotificationMarkAsReadQuery } from 'store/rtk-query/notificationsApi';
import { createUserName, transformContent } from 'utils/utils';
import { imagePathResolver } from 'utils/imageResolvers';

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const NotificationPopper = ({
  openNotification,
  anchorElNotification,
  handleCloseNotificationPopper,
  getAllNotificationsData,
  getAllNotificationsLoading,
  setOpenNotification,
  notificationCount
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [markAllRead] = useLazyMarkedAsReadNotificationQuery();

  const handleMarkAllRead = () => {
    markAllRead();
  };

  return (
    <Popper
      placement={matchesXs ? 'bottom' : 'bottom-end'}
      open={openNotification}
      anchorEl={anchorElNotification}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? -5 : 0, 9]
            }
          }
        ]
      }}
      sx={{
        boxShadow: `0px 4px 15px 0px ${theme.palette.primary.lighter} !important`
      }}
    >
      {({ TransitionProps }) => (
        <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
          <Paper
            sx={{
              width: '100%',
              minWidth: 485,
              maxWidth: 620,
              [theme.breakpoints.down('md')]: {
                maxWidth: 285
              }
            }}
          >
            <ClickAwayListener onClickAway={handleCloseNotificationPopper}>
              <MainCard
                title={<Typography sx={{ fontSize: '20px !important', color: 'primary.main' }}>Notifications</Typography>}
                elevation={0}
                border={false}
                content={false}
                secondary={
                  notificationCount > 0 && (
                    <Typography
                      sx={{ fontSize: '16px !important', color: 'primary.main', textDecoration: 'underline', cursor: 'pointer' }}
                      variant="text"
                      onClick={handleMarkAllRead}
                    >
                      Mark all read
                    </Typography>
                  )
                }
                sx={{
                  '& .MuiCardHeader-root ': {
                    padding: '20px !important',
                    paddingRight: '0px !important',
                    cursor: 'alias !important'
                  }
                }}
              >
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    '& .MuiListItemButton-root': {
                      py: 0.5,
                      '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >
                  {!getAllNotificationsLoading ? (
                    getAllNotificationsData?.resultList?.length > 0 ? (
                      getAllNotificationsData?.resultList?.map((notification) => (
                        <Box
                          key={notification.uuid}
                          sx={{ bgcolor: notification.status === notificationStatus.READ ? '' : 'rgba(46, 108, 164, 0.12)' }}
                        >
                          {notification?.type === notificationType.ADMINISTRATOR ? (
                            <>
                              <ListItemButton
                                onClick={async () => {
                                  navigate(`/notification-management/${notification.uuid}`);
                                  setOpenNotification(false);
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    sx={{
                                      color: 'success.main',
                                      bgcolor: 'white'
                                    }}
                                  >
                                    {notification?.type === notificationType.ADMINISTRATOR ? (
                                      <img
                                        src={InnofuseLogo}
                                        style={{ width: '80%', height: '80%', background: 'white' }}
                                        alt="linkedin-logo"
                                      />
                                    ) : (
                                      <IconUser />
                                    )}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                      <Typography variant="h6" sx={{ width: '70%' }} fontWeight={'500'}>
                                        {notification?.title}
                                      </Typography>
                                      <Typography sx={{ width: '30%', textAlign: 'right' }} variant="body1" color={'grey'}>
                                        {`${getMinuteDurationFromNow(notification?.createdAt)} ago`}
                                      </Typography>
                                    </Stack>
                                  }
                                  secondary={
                                    <StyledRenderHtmlBox
                                      sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 1
                                      }}
                                    >
                                      {transformContent(notification?.content)}
                                    </StyledRenderHtmlBox>
                                  }
                                />
                              </ListItemButton>
                              <Divider />
                            </>
                          ) : (
                            <UserNotificationComponent
                              notification={notification}
                              setOpenNotification={setOpenNotification}
                              navigate={navigate}
                            />
                          )}
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{
                          textAlign: 'center',
                          py: 2,
                          px: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="body1" color="text.secondary">
                          No recent notifications.
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <NotificationSkeleton />
                  )}
                  <ListItemButton
                    sx={{ textAlign: 'center', py: `${12}px !important` }}
                    onClick={() => {
                      setOpenNotification(false);
                      navigate('/notification-management');
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          View All
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </MainCard>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  );
};

NotificationPopper.propTypes = {
  openNotification: PropTypes.bool,
  anchorElNotification: PropTypes.bool,
  handleCloseNotificationPopper: PropTypes.func,
  getAllNotificationsData: PropTypes.array,
  getAllNotificationsLoading: PropTypes.bool,
  setOpenNotification: PropTypes.func,
  notificationCount: PropTypes.number,
  setOffSet: PropTypes.func,
  offset: PropTypes.number
};

export default NotificationPopper;

export const UserNotificationComponent = ({ notification, setOpenNotification }) => {
  const navigate = useNavigate();
  const [singleNotificationRead] = useLazySingleNotificationMarkAsReadQuery();
  const handleUserNotificationNavigation = async () => {
    const newsId = notification?.news?.id;
    const initiativeId = notification?.initiative?.uuid;
    const isRelatedToInitiativeNetwork = initiativeId && notification?.title?.includes('Member');

    if (newsId) {
      navigate(`/initiatives/${initiativeId}/contributions/${newsId}`);
    } else if (isRelatedToInitiativeNetwork) {
      navigate(`/initiatives/${initiativeId}/${initiativeTabs[4].value}`);
    } else if (!newsId && initiativeId) {
      navigate(`/initiatives/${initiativeId}`);
    }
    await singleNotificationRead({ notificationId: notification?.uuid });
  };

  return (
    <>
      <ListItemButton
        onClick={() => {
          handleUserNotificationNavigation();
          return setOpenNotification(false);
        }}
        sx={{ cursor: 'pointer' }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'success.main',
              bgcolor: 'white'
            }}
          >
            {notification?.type === notificationType.ADMINISTRATOR ? (
              <img src={InnofuseLogo} style={{ width: '80%', height: '80%', background: 'white' }} alt="linkedin-logo" />
            ) : imagePathResolver(notification?.userInfo?.profileImage) ? (
              <img
                src={imagePathResolver(notification?.userInfo?.profileImage)}
                style={{ width: '100%', height: '100%' }}
                alt="linkedin-logo"
              />
            ) : (
              getCapitalizeFirst(notification?.userInfo?.firstName)
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography sx={{ width: '70%' }} variant="h6" fontWeight={'500'}>
                <strong
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    const userId = notification?.userInfo?.uuid;
                    if (userId) {
                      navigate(`/network/${userId}/persons`);
                    }
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#2E6CA4B2')}
                  onMouseLeave={(e) => (e.target.style.color = 'inherit')}
                >
                  {createUserName(notification?.userInfo)}
                </strong>
                &nbsp;
                {notification?.content} &nbsp;
                <strong
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#2E6CA4B2')}
                  onMouseLeave={(e) => (e.target.style.color = 'inherit')}
                >
                  {notification?.news?.id ? notification?.news?.title : notification?.initiative?.title}
                </strong>
              </Typography>
              <Typography sx={{ width: '30%', textAlign: 'right' }} variant="body1" color={'grey'}>
                {`${getMinuteDurationFromNow(notification?.createdAt)} ago`}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
      <Divider />
    </>
  );
};

UserNotificationComponent.propTypes = {
  notification: PropTypes.object,
  setOpenNotification: PropTypes.func,
  navigate: PropTypes.func
};
