import { Avatar, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { memo, useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { StyledTitle } from 'components/InitiativeInfoSection/InviteOrAddNetwork';
import ActionComponent from 'components/@common/ActionComponent';
import { CommentLoader } from 'components/@skeletons/CommentLoader';
import { CommentComponent } from './CommentComponent';
import messages from './messages';
import {
  useAddInitiativeCommentMutation,
  useAddInitiativesReactionMutation,
  useDeleteInitiativeCommentMutation,
  useGetAllInitiativeCommentsQuery,
  useGetInitiativeReactionsQuery,
  useUpdateInitiativeCommentsMutation
} from 'store/rtk-query/initiativesApi';
import globalConstant, { RESPONSE, textMaxLength } from 'utils/constants/globalConstant';
import { CommentTextField } from 'components/Forms';
import { imagePathResolver } from 'utils/imageResolvers';

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 2, 4),
  backgroundColor: theme.palette.secondary.A400,
  borderRight: `1px solid ${theme.palette.primary.main}`
}));

export const StyledComment = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  paddingTop: theme.spacing(1),
  gap: theme.spacing(1)
}));

const InitiativeReactionSection = ({ initiativeId, userId, userImage, disableInteraction = false }) => {
  const intl = useIntl();
  const [offset, setOffSet] = useState(globalConstant.DEFAULT_PAGE_OFFSET);
  const [addInitiativesReaction] = useAddInitiativesReactionMutation();
  const [addInitiativeComment, { isLoading: addCommentIsLoading }] = useAddInitiativeCommentMutation();
  const {
    data: { resultList, totalCount: commentCount } = {},
    isLoading: AllCommentListLoading,
    error
  } = useGetAllInitiativeCommentsQuery(
    { initiativeId, offset, limit: globalConstant.DEFAULT_PAGINATION_LIMIT },
    {
      skip: !initiativeId
    }
  );

  const { data: { userHasLiked, userHasDisliked, likeCount, dislikeCount } = {} } = useGetInitiativeReactionsQuery(initiativeId, {
    skip: !initiativeId
  });

  const [deleteInitiativeComment] = useDeleteInitiativeCommentMutation();
  const [updateInitiativeComment] = useUpdateInitiativeCommentsMutation();

  useEffect(() => {
    if (error?.statusCode === RESPONSE.ERROR.FORBIDDEN) {
      toast.error(error?.data?.error);
    }
  }, [error]);

  const theme = useTheme();
  const handleActionButton = async (reaction_Type) => {
    await addInitiativesReaction({
      body: {
        type: reaction_Type,
        user: {
          uuid: userId
        }
      },
      initiativeId
    });
  };
  const [message, setMessage] = useState('');

  const handleSendClick = (setIsEmojiPickerOpen) => {
    if (message.trim() !== '') {
      addInitiativeComment({
        body: {
          text: message,
          user: {
            uuid: userId
          }
        },
        initiativeId
      });
      setIsEmojiPickerOpen(false);
      setMessage('');
    }
  };

  const updateOffset = () => {
    setOffSet((prevOffset) => prevOffset + 1);
  };

  return (
    <StyledContainer>
      <StyledTitle>Public Reaction</StyledTitle>
      <Divider sx={{ borderWidth: '1.5px', background: 'colors.main' }} />
      <Box py={1}>
        <Grid item sx={{ display: 'flex', gap: { xs: 1, md: 3 } }}>
          <ActionComponent
            likeCount={likeCount}
            dislikeCount={dislikeCount}
            commentCount={commentCount}
            isLiked={userHasLiked}
            isDisLiked={userHasDisliked}
            handleActionButton={handleActionButton}
            disable={disableInteraction}
            disableComment
          />
        </Grid>
      </Box>
      <Stack direction="row">
        <Typography
          sx={{
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            color: theme.palette.primary.light
          }}
        >
          {intl.formatMessage(messages.Comments)}
        </Typography>
      </Stack>

      <StyledComment>
        <Stack direction="row " gap={1} alignItems={'start'}>
          <Avatar src={userImage} sx={{ width: 20, height: 20 }} />
        </Stack>
        <Stack width={'85%'}>
          <CommentTextField
            label="Add Comment"
            message={message}
            setMessage={setMessage}
            placeholder=""
            handleSendClick={handleSendClick}
            onKeyDown={handleSendClick}
            onChange={(e) => setMessage(e.target.value)}
            isLoading={addCommentIsLoading}
            disable={disableInteraction}
            showCharacterCount
            maxLength={textMaxLength.DEFAULT_COMMENT_MAX_LENGTH}
          />
        </Stack>
      </StyledComment>
      {AllCommentListLoading && <CommentLoader />}
      <InfiniteScroll
        dataLength={resultList?.length || 0}
        next={updateOffset}
        hasMore={resultList?.length < commentCount}
        height={'300px'}
        style={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'none'
        }}
        hasChildren
      >
        {resultList?.map(({ id, user: { academicTitles, firstName, uuid, profileImage }, text, createdAt }) => (
          <CommentComponent
            key={id}
            commentUserId={uuid}
            commentId={id}
            name={`${academicTitles ? academicTitles : ''} ${firstName ? firstName : ''}`}
            text={text}
            createdAt={createdAt}
            theme={theme}
            userImage={imagePathResolver(profileImage)}
            deleteAction={() => {
              deleteInitiativeComment({ initiativeId, commentId: id });
            }}
            updateAction={({ body }) => {
              updateInitiativeComment({
                body,
                initiativeId,
                commentId: id
              });
            }}
            disableMenuIcon={disableInteraction}
          />
        ))}
      </InfiniteScroll>
    </StyledContainer>
  );
};

export default memo(InitiativeReactionSection);

InitiativeReactionSection.propTypes = {
  initiativeId: PropTypes.string,
  userId: PropTypes.string,
  disableInteraction: PropTypes.bool,
  userImage: PropTypes.string
};
