import { createSlice } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import messages from './message';
import paths from 'utils/constants/paths';

const stepsObject = {
  [paths.Root.url]: [
    {
      target: '.header-you',
      content: <FormattedMessage id={messages.headerYou.id} defaultMessage={messages.headerYou.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.header-feedback',
      content: <FormattedMessage id={messages.headerFeedback.id} defaultMessage={messages.headerFeedback.defaultMessage} />
    },
    {
      target: '.hamburger-menu',
      content: <FormattedMessage id={messages.HamburgerMenu.id} defaultMessage={messages.HamburgerMenu.defaultMessage} />
    },
    {
      target: '.search',
      content: <FormattedMessage id={messages.headerSearch.id} defaultMessage={messages.headerSearch.defaultMessage} />
    },
    {
      target: '.header-news',
      content: <FormattedMessage id={messages.headerNews.id} defaultMessage={messages.headerNews.defaultMessage} />
    },
    {
      target: '.my-initiatives',
      content: <FormattedMessage id={messages.myInnofuse.id} defaultMessage={messages.myInnofuse.defaultMessage} />
    },
    {
      target: '.my-initiatives-all-button',
      content: <FormattedMessage id={messages.myInnofuseAllButton.id} defaultMessage={messages.myInnofuseAllButton.defaultMessage} />
    },
    {
      target: '.add-initiative-button',
      content: (
        <FormattedMessage id={messages.myInnofuseAddInitiatives.id} defaultMessage={messages.myInnofuseAddInitiatives.defaultMessage} />
      )
    },
    {
      target: '.most-matching-initiatives',
      content: (
        <FormattedMessage id={messages.mostMatchingInitiatives.id} defaultMessage={messages.mostMatchingInitiatives.defaultMessage} />
      )
    },
    {
      target: '.most-matching-network',
      content: <FormattedMessage id={messages.mostMatchingInitiatives.id} defaultMessage={messages.mostMatchingNetwork.defaultMessage} />
    },
    {
      target: '.watched-initiatives',
      content: <FormattedMessage id={messages.mostMatchingInitiatives.id} defaultMessage={messages.watchedInitiatives.defaultMessage} />
    }
  ],
  [paths.LicenseAdministration.url]: [
    {
      target: '.header-you',
      content: <FormattedMessage id={messages.licenseAdministration.id} defaultMessage={messages.licenseAdministration.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.enterprise-image',
      content: <FormattedMessage id={messages.enterpriseImage.id} defaultMessage={messages.enterpriseImage.defaultMessage} />
    },
    {
      target: '.enterprise-details',
      content: <FormattedMessage id={messages.enterpriseDetails.id} defaultMessage={messages.enterpriseDetails.defaultMessage} />
    },
    {
      target: '.enterprise-keywords',
      content: <FormattedMessage id={messages.enterpriseKeywords.id} defaultMessage={messages.enterpriseKeywords.defaultMessage} />
    },
    {
      target: '.enterprise-users',
      content: <FormattedMessage id={messages.enterpriseUsers.id} defaultMessage={messages.enterpriseUsers.defaultMessage} />
    }
  ],
  [paths.Profile.url]: [
    {
      target: '.header-you',
      content: <FormattedMessage id={messages.profile.id} defaultMessage={messages.profile.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.profile-image',
      content: <FormattedMessage id={messages.profileImage.id} defaultMessage={messages.profileImage.defaultMessage} />
    },
    {
      target: '.profile-details',
      content: <FormattedMessage id={messages.profileDetails.id} defaultMessage={messages.profileDetails.defaultMessage} />
    },
    {
      target: '.profile-keywords',
      content: <FormattedMessage id={messages.profileKeywords.id} defaultMessage={messages.profileKeywords.defaultMessage} />
    },
    {
      target: '.profile-extra-fields',
      content: <FormattedMessage id={messages.profileExtraFields.id} defaultMessage={messages.profileExtraFields.defaultMessage} />
    }
  ],
  [paths.Chats.url]: [
    {
      target: '.chat',
      content: <FormattedMessage id={messages.chat.id} defaultMessage={messages.chat.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.chat-users-0',
      content: <FormattedMessage id={messages.chatUsers.id} defaultMessage={messages.chatUsers.defaultMessage} />
    },
    {
      target: '.chat-user-search',
      content: <FormattedMessage id={messages.chatUserSearch.id} defaultMessage={messages.chatUserSearch.defaultMessage} />
    },
    {
      target: '.chat-message',
      content: <FormattedMessage id={messages.chatMessage.id} defaultMessage={messages.chatMessage.defaultMessage} />
    }
  ],
  [paths.Reactor.url]: [
    {
      target: '.reactor-search',
      content: <FormattedMessage id={messages.reactorSearch.id} defaultMessage={messages.reactorSearch.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.reactor-tabs',
      content: <FormattedMessage id={messages.reactorTabs.id} defaultMessage={messages.reactorTabs.defaultMessage} />
    },
    {
      target: '.reactor-button',
      content: <FormattedMessage id={messages.reactorButton.id} defaultMessage={messages.reactorButton.defaultMessage} />
    },
    {
      target: '.reactor-initiatives',
      content: <FormattedMessage id={messages.reactorInitiatives.id} defaultMessage={messages.reactorInitiatives.defaultMessage} />
    },
    {
      target: '.reactor-initiatives-score',
      content: (
        <FormattedMessage id={messages.reactorInitiativesScore.id} defaultMessage={messages.reactorInitiativesScore.defaultMessage} />
      )
    }
  ],
  [paths.Network.url]: [
    {
      target: '.search',
      content: <FormattedMessage id={messages.headerSearch.id} defaultMessage={messages.headerSearch.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.network-card',
      content: <FormattedMessage id={messages.networkCard.id} defaultMessage={messages.networkCard.defaultMessage} />
    },

    {
      target: '.network-tabs',
      content: <FormattedMessage id={messages.networkTabs.id} defaultMessage={messages.networkTabs.defaultMessage} />
    }
  ],
  [paths.Initiatives.url]: [
    {
      target: '.search',
      content: <FormattedMessage id={messages.headerSearch.id} defaultMessage={messages.headerSearch.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.initiatives-card-0',
      content: <FormattedMessage id={messages.initiativesCard.id} defaultMessage={messages.initiativesCard.defaultMessage} />
    },
    {
      target: '.initiatives-icon',
      content: <FormattedMessage id={messages.initiativesMenuIcon.id} defaultMessage={messages.initiativesMenuIcon.defaultMessage} />
    },
    {
      target: '.initiatives-add',
      content: <FormattedMessage id={messages.initiativesAddButton.id} defaultMessage={messages.initiativesAddButton.defaultMessage} />
    },
    {
      target: '.initiatives-tabs',
      content: <FormattedMessage id={messages.initiativesTabs.id} defaultMessage={messages.initiativesTabs.defaultMessage} />
    }
  ],
  [paths.AddInitiatives.url]: [
    {
      target: '.initiatives-privacy',
      content: <FormattedMessage id={messages.initiativesPrivacy.id} defaultMessage={messages.initiativesPrivacy.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.initiatives-state',
      content: <FormattedMessage id={messages.initiativesState.id} defaultMessage={messages.initiativesState.defaultMessage} />
    },
    {
      target: '.initiatives-visibility',
      content: <FormattedMessage id={messages.initiativesVisibility.id} defaultMessage={messages.initiativesVisibility.defaultMessage} />
    },
    {
      target: '.initiatives-visibility-button',
      content: (
        <FormattedMessage
          id={messages.initiativesVisibilityButton.id}
          defaultMessage={messages.initiativesVisibilityButton.defaultMessage}
        />
      )
    }
  ],
  [paths.SelectInitiative.url]: [
    {
      target: '.initiatives-type',
      content: <FormattedMessage id={messages.initiativesTypes.id} defaultMessage={messages.initiativesTypes.defaultMessage} />,
      disableBeacon: true
    },
    {
      target: '.initiatives-subtype',
      content: <FormattedMessage id={messages.initiativesSubTypes.id} defaultMessage={messages.initiativesSubTypes.defaultMessage} />
    }
  ]
};

// initial state
const initialState = {
  tourState: {
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: stepsObject[paths.Root.url],
    stepKeys: '/'
  }
};

// ==============================|| SLICE - MENU ||============================== //

export const tourGuideReducer = createSlice({
  name: 'tourGuideReducer',
  initialState,
  reducers: {
    setTourState(state, { payload }) {
      switch (payload.type) {
        case 'START':
          state.tourState = {
            ...state.tourState,
            steps: stepsObject[payload.stepKeys],
            run: true
          };
          break;
        case 'RESET':
          state.tourState = {
            ...state.tourState,
            stepIndex: 0
          };
          break;
        case 'STOP':
          state.tourState = {
            ...state.tourState,
            run: false
          };
          break;
        case 'NEXT_OR_PREV':
          state.tourState = {
            ...state.tourState,
            ...payload?.payload
          };
          break;
        case 'RESTART':
          state.tourState = {
            ...state.tourState,
            steps: stepsObject[payload.stepKeys],
            stepIndex: 0,
            run: true,
            loading: false,
            stepKeys: payload.stepKeys
          };
          break;
      }
    }
  }
});

export const { setTourState } = tourGuideReducer.actions;
