import { defineMessages } from 'react-intl';

export const scope = 'app.pages.login';

export default defineMessages({
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email ID'
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password'
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'New Password'
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm New Password'
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Set Password'
  },
  forgetPasswordButtonText: {
    id: `${scope}.forgetPasswordButtonText`,
    defaultMessage: 'Forgot Password?'
  },
  loginButtonText: {
    id: `${scope}.loginButtonText`,
    defaultMessage: 'Login'
  },
  setPasswordButtonText: {
    id: `${scope}.setPasswordButtonText`,
    defaultMessage: 'Set Password'
  },
  // place holders
  emailPlaceholder: {
    id: `${scope}.emailPlaceholder`,
    defaultMessage: 'Write your Email ID here'
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: 'Please enter your password'
  },
  otpPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: 'Please enter your OTP'
  },
  newPasswordPlaceholder: {
    id: `${scope}.newPasswordPlaceholder`,
    defaultMessage: 'Enter a new password'
  },
  confirmPasswordPlaceholder: {
    id: `${scope}.confirmPasswordPlaceholder`,
    defaultMessage: 'Re-enter the new password'
  },
  SomeThingWentWrongPleaseTryAgain: {
    id: `${scope}.SomeThingWentWrongPleaseTryAgain`,
    defaultMessage: 'Something went Wrong !. Please try again.'
  },
  LoginSuccessFull: {
    id: `${scope}.LoginSuccessFull`,
    defaultMessage: 'Login Successfull !'
  },
  Accept: {
    id: `${scope}.Accept`,
    defaultMessage: 'Accept'
  },
  TermsAndConditions: {
    id: `${scope}.TermsAndConditions`,
    defaultMessage: 'Terms and Conditions'
  },
  LogInWithOTP: {
    id: `${scope}.LogInWithOTP`,
    defaultMessage: 'or Login with OTP'
  },
  LogInWithEmail: {
    id: `${scope}.LogInWithEmail`,
    defaultMessage: 'or Login with Email'
  }
});
