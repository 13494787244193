import { Avatar, Stack, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { colors } from 'utils/constants/colors';
import { getCapitalizeFirst } from 'utils/getCapitalize';

const InitiativeMemberBoxImageWithAvatar = ({
  label1 = '',
  label1Color,
  label2 = '',
  label2Color = colors.dark[550],
  label3 = '',
  label3Color = colors.dark[550],
  avatarSrc,
  avatarSize = 50,
  onClick,
  isDisabled = false
}) => {
  return (
    <Box onClick={onClick} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem', cursor: 'pointer' }}>
      <Avatar
        sx={{ width: avatarSize, height: avatarSize }}
        alt="user profile pic"
        src={avatarSrc}
        // eslint-disable-next-line react/no-children-prop
        children={getCapitalizeFirst(label1)}
      />
      <Stack alignItems={'flex-start'} textAlign={'left'}>
        <Typography textTransform={'capitalize'} color={isDisabled ? colors.dark[550] : label1Color} fontSize={'1rem'} variant="h6">
          {label1}
        </Typography>
        <Typography
          visibility={label2 ? 'visible' : 'hidden'}
          textTransform={'capitalize'}
          color={label2Color}
          fontSize={'0.9rem'}
          variant="h6"
        >
          {label2 || '_'}
        </Typography>

        <Typography
          mt={-0.5}
          visibility={label3 ? 'visible' : 'hidden'}
          textTransform={'capitalize'}
          color={label3Color}
          fontSize={'0.7rem'}
          fontStyle={'italic'}
          variant="h6"
        >
          {label3 || '_'}
        </Typography>
      </Stack>
    </Box>
  );
};

export default InitiativeMemberBoxImageWithAvatar;

InitiativeMemberBoxImageWithAvatar.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  avatarSrc: PropTypes.string,
  avatarSize: PropTypes.string,
  label1Color: PropTypes.string,
  label2Color: PropTypes.string,
  label3Color: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
};
