import { Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NetworkDetailBox } from './NetworkDetailBox';
import { imagePathResolver } from 'utils/imageResolvers';

const NetworkList = ({ combinedAllNetworkData, visibleForAdministrators, userId, ownerData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigateToNetwork = (networkId, networkType) => {
    navigate(`/network/${networkId}/${networkType}`);
  };

  if (!combinedAllNetworkData || Object.keys(combinedAllNetworkData).length === 0) {
    return null;
  }

  return (
    combinedAllNetworkData &&
    Object.keys(combinedAllNetworkData)?.length > 0 &&
    Object.keys(combinedAllNetworkData).map((member, index) => (
      <Stack
        key={index}
        gap={2}
        borderBottom={
          (combinedAllNetworkData[member]?.length > 0 || Object.keys(combinedAllNetworkData[member] || {})?.length > 0) &&
          `0.5px solid ${theme.palette.primary.lighter}`
        }
      >
        <Typography mt={2} textTransform={'capitalize'} color={'common.black'} fontSize={'1rem'} fontWeight={500} variant="h6">
          {(combinedAllNetworkData[member]?.length > 0 || Object.keys(combinedAllNetworkData[member])?.length > 0) && member}
        </Typography>
        {Array.isArray(combinedAllNetworkData[member]) ? (
          combinedAllNetworkData[member]?.length > 0 &&
          combinedAllNetworkData[member].map((item) => (
            <NetworkDetailBox
              key={item.uuid}
              memberUserId={item.uuid}
              ownerUserId={userId}
              userName={item.name ? item.name : `${item?.academicTitles || ''} ${item?.firstName || 'Anonymous'} ${item?.lastName || ''}`}
              isOrganization={!!item.name && item.label === 'Enterprise'}
              currentPosition={item.label}
              showOrganizationName={item?.organization && item?.organization?.name}
              showRemoveButton={
                (visibleForAdministrators && item.label === 'Member') ||
                (visibleForAdministrators && item.label === 'Enterprise') ||
                (userId === ownerData[0]?.uuid && item.label === 'Administrator')
              }
              profileImage={imagePathResolver(item?.profileImage)}
              keywords={item?.keywords}
              administratorsArr={item.label === 'Administrator' && [...combinedAllNetworkData[member], ...ownerData]}
              onNetworkDetailClick={() =>
                handleNavigateToNetwork(
                  !!item.name && item.label === 'Enterprise' ? item?.uuid : item?.uuid,
                  !!item.name && item.label === 'Enterprise' ? 'enterprises' : 'persons'
                )
              }
              disable={item?.isAdminInvitee === true}
            />
          ))
        ) : (
          <NetworkDetailBox
            memberUserId={combinedAllNetworkData[member]?.uuid}
            userName={
              combinedAllNetworkData[member].name
                ? combinedAllNetworkData[member].name
                : `${combinedAllNetworkData[member]?.academicTitles || ''} ${combinedAllNetworkData[member]?.firstName || 'Anonymous'} ${
                    combinedAllNetworkData[member]?.lastName || ''
                  }`
            }
            isOrganization={!!combinedAllNetworkData[member]?.name && combinedAllNetworkData[member]?.label === 'Enterprise'}
            currentPosition={combinedAllNetworkData[member]?.label}
            showOrganizationName={combinedAllNetworkData[member]?.organization && combinedAllNetworkData[member]?.organization?.name}
            showRemoveButton={(visibleForAdministrators && member === 'Member') || (visibleForAdministrators && member === 'Enterprise')}
            profileImage={imagePathResolver(combinedAllNetworkData[member]?.profileImage)}
            keywords={combinedAllNetworkData[member]?.keywords}
            onNetworkDetailClick={() =>
              handleNavigateToNetwork(
                !!combinedAllNetworkData[member]?.name && (combinedAllNetworkData[member]?.label === 'Enterprise') === 'Enterprise'
                  ? combinedAllNetworkData[member]?.uuid
                  : combinedAllNetworkData[member]?.uuid,
                !!combinedAllNetworkData[member]?.name && (combinedAllNetworkData[member]?.label === 'Enterprise') === 'Enterprise'
                  ? 'enterprises'
                  : 'persons'
              )
            }
            disable={combinedAllNetworkData[member]?.isAdminInvitee === true}
          />
        )}
      </Stack>
    ))
  );
};

NetworkList.propTypes = {
  combinedAllNetworkData: PropTypes.object,
  visibleForAdministrators: PropTypes.bool,
  userId: PropTypes.object,
  ownerData: PropTypes.array
};

export default NetworkList;
