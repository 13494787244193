import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ACTIVITY_NAME } from 'utils/constants/initiatives';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import paths from 'utils/constants/paths';
import removeUnderScore from 'utils/removeUnderscore';

const Activities = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.primary.mainLight}`,
  alignItems: 'center',
  padding: '8px',
  '&:hover': {
    background: theme.palette.secondary.cardBackground,
    display: 'flex',
    justifyContent: 'space-between'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8
  }
}));

const ActivitiesTitleContainer = styled(Grid)``;
const ActivitiesTitle = styled(Typography)(({ theme }) => ({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 450,
  lineHeight: '110%',
  textTransform: 'capitalize',
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px'
  }
}));

const ActivitiesDescription = styled(Typography)(({ theme }) => ({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '110%',
  [theme.breakpoints.down('md')]: {
    fontSize: '10px'
  }
}));

const ActivitiesEntityTitle = styled(Typography)(({ theme }) => ({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '110%',
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '10px'
  }
}));
const ActivitiesDetailContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
const ActivitiesDetailContainerUpper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 15,
  justifyContent: 'space-between',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '110%',
  textTransform: 'capitalize',
  svg: {
    color: theme.palette.primary.light,
    [theme.breakpoints.down('md')]: {
      width: 16,
      height: 16
    }
  },
  [theme.breakpoints.down('sm')]: {
    gap: 5
  }
}));
const ActivitiesDetailContainerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '12px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px'
  }
}));
const ActivitiesDetailContainerLower = styled(Stack)(() => ({
  justifyContent: 'space-between'
}));
const ActivitiesDetailContainerLowerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '110%',
  textTransform: 'capitalize',
  [theme.breakpoints.down('md')]: {
    fontSize: '10px'
  }
}));

const ActivityInfo = ({
  activityDate,
  activityTime,
  tableName,
  message,
  rightIconToRender,
  userName,
  entityName,
  entityId,
  uuid: userId,
  initiative
}) => {
  const navigate = useNavigate();
  const { data: { uuid: loggedInUserId = '' } = {} } = useGetUserInfoQuery();

  const initiativeId = initiative?.uuid;

  const handleRoutes = (redirectionId, table, entityRelationId, activityMessage) => {
    if (!redirectionId) return;

    const baseRoute = `/initiatives/${entityRelationId}`;
    switch (table.toLowerCase()) {
      case ACTIVITY_NAME.CONTRIBUTIONS:
        return navigate(`${baseRoute}/${table}/${redirectionId}`);
      case ACTIVITY_NAME.INITIATIVE:
        return navigate(`/initiatives/${redirectionId}`);
      case ACTIVITY_NAME.Linked_Initiative:
        return navigate(`/initiatives/${redirectionId}`);
      case ACTIVITY_NAME.ORGANIZATION_AUTHORITY:
        return navigate(`/network/${redirectionId}/enterprises`);
      case ACTIVITY_NAME.Initiative_INVITEE:
        return navigate(`/network/${redirectionId}/persons`);
      case ACTIVITY_NAME.NETWORK:
        if (redirectionId === loggedInUserId) {
          navigate(paths.Profile.url);
          return;
        }
        return navigate(`/network/${redirectionId}/persons`);
      case ACTIVITY_NAME.Initiative_MEMBER:
        return navigate(`/initiatives/${redirectionId}`);
      case ACTIVITY_NAME.REACTION:
        return navigate(activityMessage?.includes(ACTIVITY_NAME.CONTRIBUTIONS) ? `${baseRoute}/${ACTIVITY_NAME.CONTRIBUTIONS}` : baseRoute);
      case ACTIVITY_NAME.COMMENT:
        return navigate(activityMessage?.includes(ACTIVITY_NAME.CONTRIBUTIONS) ? `${baseRoute}/${ACTIVITY_NAME.CONTRIBUTIONS}` : baseRoute);
      default:
        return null;
    }
  };

  return (
    <Activities item container xs={12}>
      <ActivitiesTitleContainer item xs={11}>
        <Stack direction="row" gap={1} alignItems={'center'}>
          <ActivitiesTitle onClick={() => handleRoutes(userId, ACTIVITY_NAME.NETWORK)}>
            <strong>{userName}</strong>
          </ActivitiesTitle>
          <ActivitiesDescription>{message}</ActivitiesDescription>
          <ActivitiesEntityTitle onClick={() => handleRoutes(entityId, tableName, initiativeId, message)}>
            <strong>{entityName && entityName}</strong>
          </ActivitiesEntityTitle>
        </Stack>
      </ActivitiesTitleContainer>
      <ActivitiesDetailContainer item xs={1}>
        <ActivitiesDetailContainerUpper>
          <Box>{rightIconToRender}</Box>
          <ActivitiesDetailContainerText>{removeUnderScore(tableName)}</ActivitiesDetailContainerText>
        </ActivitiesDetailContainerUpper>
        <ActivitiesDetailContainerLower direction={'row'} gap={1}>
          <ActivitiesDetailContainerLowerText>{activityDate}</ActivitiesDetailContainerLowerText>
          <ActivitiesDetailContainerLowerText>{activityTime}</ActivitiesDetailContainerLowerText>
        </ActivitiesDetailContainerLower>
      </ActivitiesDetailContainer>
    </Activities>
  );
};

export default ActivityInfo;

ActivityInfo.propTypes = {
  tableName: PropTypes.string,
  message: PropTypes.string,
  activityDate: PropTypes.string,
  activityTime: PropTypes.string,
  rightIconToRender: PropTypes.element,
  userName: PropTypes.string,
  entityName: PropTypes.string,
  entityId: PropTypes.string,
  uuid: PropTypes.string,
  initiative: PropTypes.object
};
